import React from "react";

function AbtBanner({ data: response }) {
  const { data } = response || { data: [] };
  return (
    <>
      <section className="row_inner_am banner_card_section nassa_highlight">
        <div className="container">
          <div className="row">
            {data &&
              data?.length > 0 &&
              data.map((item, idx) => (
                <div className="col-md-4" key={item?.id}>
                  <div
                    className="blog_card"
                    data-aos="fade-up"
                    data-aos-duration={1500}
                  >
                    <img
                      src={`${process.env.REACT_APP_UPLOAD_URL}${item?.imagePath}`}
                      alt={item?.label}
                      className="img-fluid round"
                    />
                  </div>
                  <div className="highlight_text">
                    {item?.label && <h3>{item?.label}</h3>}
                    {item?.content && (
                      <div
                        className="lead"
                        dangerouslySetInnerHTML={{ __html: item?.content }}
                      ></div>
                    )}
                  </div>
                </div>
              ))}
          </div>
        </div>
      </section>
    </>
  );
}

export default AbtBanner;
