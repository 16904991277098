import React from "react";

import PromoOne from "../../assets/images/orions/promo-1.jpg";
import PromoTwo from "../../assets/images/orions/promo-2.jpg";
import PromoThree from "../../assets/images/orions/promo-3.jpg";

function Promotions({ data: response, header }) {
  const { data } = response || { data: [] };
  return (
    <>
      <section className="orion_events">
        <div className="mb-5 pb-5">
          <div className="section_title text-center pt-5 mb-5 mt-5">
            {header?.subTitle && (
              <span
                className="sub_text mb-1"
                data-aos="fade-down"
                data-aos-duration={1500}
              >
                {header?.subTitle}
              </span>
            )}
            {header?.title && (
              <h2 data-aos="zoom-in" data-aos-duration={1500}>
                {header?.title}
              </h2>
            )}
          </div>
          <div className="row no-gap mb-5">
            {data?.length > 0 &&
              data.map((item, index) => (
                <div
                  key={item?.id}
                  className="col-md-4 position-relative orion_card"
                >
                  <img
                    src={`${process.env.REACT_APP_UPLOAD_URL}${item?.imagePath}`}
                    alt={`OrionsPromo-${item.id}`}
                    id={`OrionsPromo-${item.id}`}
                    className="img-fluid"
                  />
                </div>
              ))}
            {/* <div className="col-md-4 position-relative orion_card">
              <img
                src={PromoOne}
                alt="promotions"
                className="img-fluid w-100"
              />
            </div>
            <div className="col-md-4 position-relative orion_card">
              <img
                src={PromoTwo}
                alt="promotions"
                className="img-fluid w-100"
              />
            </div>
            <div className="col-md-4 position-relative orion_card">
              <img
                src={PromoThree}
                alt="promotions"
                className="img-fluid w-100"
              />
            </div> */}
          </div>
          <div className="text-center">
            <button className="btn btn_primary">View All</button>
          </div>
        </div>
      </section>
    </>
  );
}

export default Promotions;
